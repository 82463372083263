.item_icon[data-v-8cd0123c] {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-8cd0123c] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-8cd0123c] {
  display: inline-block;
  font-size: 13px;
}
.top_select[data-v-8cd0123c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
[data-v-8cd0123c] .el-step__title {
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
}
[data-v-8cd0123c] .el-radio-button__inner {
  padding: 8px 25px;
}
.tabs[data-v-8cd0123c] {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 229, 229);
}
.tabs img[data-v-8cd0123c] {
  width: 147px;
  height: 80px;
}
.tab_content[data-v-8cd0123c] {
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 8px;
}
.tab_content.active[data-v-8cd0123c] {
  color: #17a2b8;
  border-bottom: 1px solid #17a2b8;
}
.tab_title[data-v-8cd0123c] {
  font-size: 13px;
}
.tab_flex[data-v-8cd0123c] {
  display: flex;
  align-items: center;
}
.triangle-container[data-v-8cd0123c] {
  margin: 0 30px;
}
.triangle-container img[data-v-8cd0123c] {
  width: 26px;
  height: auto;
}
.back_btn[data-v-8cd0123c] {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #17a2b8;
  cursor: pointer;
}
.back_btn .icon-ai207[data-v-8cd0123c] {
  margin-right: 4px;
}