.main_content[data-v-28a3c129] {
  display: flex;
  align-items: center;
  margin: 40px 0 0 30px;
}
.each_pic[data-v-28a3c129] {
  width: 390px;
  position: relative;
  margin-right: 30px;
}
.each_pic img[data-v-28a3c129] {
  width: 100%;
  height: auto;
}
.each_pic .each_title[data-v-28a3c129] {
  position: absolute;
  bottom: 22%;
  right: 10%;
  font-size: 13px;
  letter-spacing: 1px;
  background-color: #17a2b8;
  padding: 6px 15px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.pic_hint[data-v-28a3c129] {
  position: absolute;
  right: 10%;
  top: 40px;
}
.pic_hint .hint_title[data-v-28a3c129] {
  color: transparent;
  text-align: right;
  font-size: 16px;
  background-image: linear-gradient(to right, #107b8b, #12cae7);
  -webkit-background-clip: text;
          background-clip: text;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.each_text[data-v-28a3c129] {
  font-size: 13px;
}
.each_text[data-v-28a3c129]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  margin-right: 4px;
}